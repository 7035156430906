import React, { ReactNode, useState, useEffect } from 'react';
import styles from './Layout.module.scss';
import Sidebar from '../Sidebar/Sidebar';
import { NavLink, useLocation } from 'react-router-dom';
import cx from 'classnames';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import Navigation from '../Navigation/Navigation';
import PublicNavigation from '../PublicNavigation/PublicNavigation';
import {
  faUsers,
  faGlobe,
  faBath,
  faCity,
  faBuilding,
  faLocationDot,
  faServer,
  faBed,
  faLanguage,
  faBook,
  faEnvelopeOpenText,
  faTreeCity,
} from '@fortawesome/free-solid-svg-icons';
import { routes } from '../../config/Router/routes';
import { useIntl } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import { Language } from '../../domain/Language';
import { Locale } from '../../domain/Translation';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { connect } from 'react-redux';
import { User } from '../../domain/User';
import { Roles } from '../../domain/Role';
import { getListName, getLocaleUrl } from '../../utility/url/urlHelper';
import Footer from '../Footer/Footer';
import ContactBox from './ContactBox/ContactBox';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { resetSavedFiltersStore } from '../../store/user/actions';
import { SavedUserFilters } from '../../store/user/service';

export type Props = {
  children: ReactNode;
  isAuthenticated: boolean;
  selectedLocale: Locale;
  languages: Language[];
  currentUser: User | null;
  savedFilters: SavedUserFilters | null;
  onResetSavedFilters: () => void;
};

export type NavigationItem = {
  label: string;
  to: string;
  icon?: IconProp;
  state?: string;
};

export type NavigationGroup = {
  label: string;
  items: NavigationItem[];
};

const MOBILE_BREAK_POINT = 900;

const Layout = ({
  children,
  isAuthenticated,
  selectedLocale,
  languages,
  currentUser,
  savedFilters,
  onResetSavedFilters,
}: Props) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const routeLocation = useLocation();
  const intl = useIntl();

  const listName = getListName(location.pathname);

  useEffect(() => {
    if (listName !== savedFilters?.listName) {
      onResetSavedFilters();
    }
  }, [listName]);

  const getNavigationItems = () => {
    return [
      {
        label: translate(intl, 'NAVIGATION.HOME'),
        to: getLocaleUrl(routes.homepage, selectedLocale).replace(
          ':slug',
          translate(intl, 'NAVIGATION.HOME_LINK').toLowerCase(),
        ),
      },
      {
        label: translate(intl, 'NAVIGATION.PROPERTIES'),
        to: getLocaleUrl(routes.propertiesListPage, selectedLocale).replace(
          ':slug',
          translate(intl, 'NAVIGATION.PROPERTIES_LINK').toLowerCase(),
        ),
      },
      {
        label: translate(intl, 'NAVIGATION.GUIDES'),
        to: getLocaleUrl(routes.guidesPage, selectedLocale).replace(
          ':slug',
          translate(intl, 'NAVIGATION.GUIDES_LINK').toLowerCase(),
        ),
      },
      {
        label: translate(intl, 'NAVIGATION.ABOUT'),
        to: getLocaleUrl(routes.aboutUsPage, selectedLocale).replace(
          ':slug',
          translate(intl, 'NAVIGATION.ABOUT_LINK').toLowerCase(),
        ),
      },
      {
        label: translate(intl, 'NAVIGATION.SERVICES'),
        to: getLocaleUrl(routes.servicesPage, selectedLocale).replace(
          ':slug',
          translate(intl, 'NAVIGATION.SERVICES_LINK').toLowerCase(),
        ),
      },
      {
        label: translate(intl, 'NAVIGATION.CONTACT'),
        to: getLocaleUrl(routes.contact, selectedLocale).replace(
          ':slug',
          translate(intl, 'NAVIGATION.CONTACT_LINK').toLowerCase(),
        ),
      },
    ];
  };

  const getFooterNavigationItems = () => {
    return [
      {
        label: translate(intl, 'NAVIGATION.SELL_HOME'),
        to: getLocaleUrl(routes.contact, selectedLocale).replace(
          ':slug',
          translate(intl, 'NAVIGATION.SELL_HOME_LINK').toLowerCase(),
        ),
        state: 'sellMyHome',
      },
      {
        label: translate(intl, 'NAVIGATION.BUY_HOME'),
        to: getLocaleUrl(routes.propertiesListPage, selectedLocale).replace(
          ':slug',
          translate(intl, 'NAVIGATION.BUY_HOME_LINK').toLowerCase(),
        ),
      },
      {
        label: translate(intl, 'NAVIGATION.ABOUT_US'),
        to: getLocaleUrl(routes.aboutUsPage, selectedLocale).replace(
          ':slug',
          translate(intl, 'NAVIGATION.ABOUT_US_LINK').toLowerCase(),
        ),
      },
      {
        label: translate(intl, 'NAVIGATION.GUIDES'),
        to: getLocaleUrl(routes.guidesPage, selectedLocale).replace(
          ':slug',
          translate(intl, 'NAVIGATION.GUIDES_LINK').toLowerCase(),
        ),
      },
    ];
  };

  useEffect(() => {
    window.scroll({ top: 0 });
  }, [routeLocation.pathname]);

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [routeLocation]);

  const { width } = useWindowSize();

  const SIDEBAR_ITEMS = [
    {
      label: translate(intl, 'NAVIGATION.GROUP_PROPERTIES_MANAGEMENT'),
      items: [
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_LOCATIONS'),
          to: routes.locations.list,
          icon: faBuilding as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_AMENITIES'),
          to: routes.amenities.list,
          icon: faBath as IconProp,
          roles: [Roles.ADMIN, Roles.EMPLOYEE],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_PROPERTIES'),
          to: routes.properties.list,
          icon: faBed as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_REMOTE_LOCATIONS'),
          to: routes.remoteLocations.list,
          icon: faLocationDot as IconProp,
          roles: [Roles.ADMIN],
        },
      ],
    },
    {
      label: translate(intl, 'NAVIGATION.GROUP_LOCATIONS_MANAGEMENT'),
      items: [
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_CITIES'),
          to: routes.cities.list,
          icon: faCity as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_TOWN_AREAS'),
          to: routes.townAreas.list,
          icon: faTreeCity as IconProp,
          roles: [Roles.ADMIN],
        },
      ],
    },
    {
      label: translate(intl, 'NAVIGATION.GROUP_GLOBAL'),
      items: [
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_ENQUIRIES'),
          to: routes.enquiries.list,
          icon: faEnvelopeOpenText as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_EMPLOYEES'),
          to: routes.employees.list,
          icon: faUsers as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_GUIDES'),
          to: routes.guides.list,
          icon: faBook as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_INTEGRATIONS'),
          to: routes.integrations.list,
          icon: faServer as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_USERS'),
          to: routes.users.list,
          icon: faUsers as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_LANGUAGES'),
          to: routes.languages.list,
          icon: faLanguage as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_TRANSLATIONS'),
          to: routes.translations,
          icon: faGlobe as IconProp,
          roles: [Roles.ADMIN],
        },
      ],
    },
  ];

  useEffect(() => {
    if (isMobileMenuOpen) {
      window.scroll({ top: 0 });
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isMobileMenuOpen]);

  const getNavigationGroups = () => {
    return SIDEBAR_ITEMS.map((sidebarItemGroup) => ({
      label: sidebarItemGroup.label,
      items: sidebarItemGroup.items.filter((sidebarItem) => {
        return (
          !!currentUser?.role && sidebarItem.roles.includes(currentUser.role)
        );
      }),
    }));
  };

  if (routeLocation.pathname.includes(routes.login)) {
    return <>{children}</>;
  }

  if (!routeLocation.pathname.includes(routes.admin)) {
    return (
      <div className={styles.publicNavigationWrapper}>
        <PublicNavigation
          onDrawerClick={() => setIsMobileMenuOpen((prev) => !prev)}
          isMobileMenuOpen={isMobileMenuOpen}
          navigationItems={getNavigationItems()}
          isAuthenticated={isAuthenticated}
        />
        <div className={styles.publicContainer}>{children}</div>
        <Footer
          navigationItems={getFooterNavigationItems()}
          isSecondIllustration={routeLocation.pathname.includes(routes.contact)}
        />
        <ContactBox />
      </div>
    );
  }

  return (
    <>
      <div className={styles.container}>
        {width && width >= MOBILE_BREAK_POINT && (
          <Sidebar navigationGroups={getNavigationGroups()} />
        )}
        <div
          className={cx(styles.rightSection, {
            [styles.noScroll]: isMobileMenuOpen,
          })}
        >
          <Navigation
            onDrawerClick={() => setIsMobileMenuOpen((prev) => !prev)}
            isMobileMenuOpen={isMobileMenuOpen}
            isAuthenticated={isAuthenticated}
          />
          <div className={styles.content}>{children}</div>
        </div>
      </div>
      {isMobileMenuOpen && width && width < MOBILE_BREAK_POINT && (
        <div className={styles.mobileDrawer}>
          {getNavigationGroups().map((navigationGroup) => (
            <div className={styles.navigationGroup} key={navigationGroup.label}>
              <div className={styles.groupName}>{navigationGroup.label}</div>
              {navigationGroup.items.map((item) => (
                <NavLink
                  key={item.label}
                  to={item.to}
                  className={({ isActive }) =>
                    cx(styles.navigationItem, {
                      [styles.activeSubItem]: isActive,
                    })
                  }
                >
                  {item.label}
                </NavLink>
              ))}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedLocale: state.auth.selectedLocale,
  languages: state.language.languages,
  currentUser: state.user.currentUser,
  savedFilters: state.user.savedFilters,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onResetSavedFilters: () => dispatch(resetSavedFiltersStore()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
