import * as actionTypes from './actionTypes';
import { LocationIntegrationActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { LocationIntegration } from '../../domain/LocationIntegration';

export type LocationIntegrationStateType = {
  locationIntegrationsList: ListResults<LocationIntegration> | null;
  locationIntegrationsListLoading: boolean;
  locationIntegrationsListError: HttpError;
  locationIntegrationsListUpdateNeeded: boolean;
  locationIntegrationToggleLoading: boolean;
  locationIntegrationToggleError: HttpError;
  locationIntegrationToggleSuccess: boolean;
  locationIntegrations: LocationIntegration[] | null;
  locationIntegrationsLoading: boolean;
  locationIntegrationsError: HttpError;
};

export type LocationIntegrationActionType = LocationIntegrationStateType & {
  type: LocationIntegrationActionTypes;
};

export const initialState: LocationIntegrationStateType = {
  locationIntegrationsList: null,
  locationIntegrationsListLoading: true,
  locationIntegrationsListError: null,
  locationIntegrationsListUpdateNeeded: false,
  locationIntegrationToggleLoading: false,
  locationIntegrationToggleSuccess: false,
  locationIntegrationToggleError: null,
  locationIntegrations: null,
  locationIntegrationsLoading: false,
  locationIntegrationsError: null,
};

const fetchIntegrationsListStart = (
  state: LocationIntegrationStateType,
): LocationIntegrationStateType => ({
  ...state,
  locationIntegrationsListLoading: true,
});

const fetchIntegrationsListSuccess = (
  state: LocationIntegrationStateType,
  action: LocationIntegrationActionType,
): LocationIntegrationStateType => ({
  ...state,
  locationIntegrationsListLoading: false,
  locationIntegrationsList: action.locationIntegrationsList,
  locationIntegrationsListUpdateNeeded: false,
});

const fetchIntegrationsListFail = (
  state: LocationIntegrationStateType,
  action: LocationIntegrationActionType,
): LocationIntegrationStateType => ({
  ...state,
  locationIntegrationsListLoading: false,
  locationIntegrationsListError: action.locationIntegrationsListError,
});

const fetchIntegrationsStart = (
  state: LocationIntegrationStateType,
): LocationIntegrationStateType => ({
  ...state,
  locationIntegrationsLoading: true,
});

const fetchIntegrationsSuccess = (
  state: LocationIntegrationStateType,
  action: LocationIntegrationActionType,
): LocationIntegrationStateType => ({
  ...state,
  locationIntegrationsLoading: false,
  locationIntegrations: action.locationIntegrations,
});

const fetchIntegrationsFail = (
  state: LocationIntegrationStateType,
  action: LocationIntegrationActionType,
): LocationIntegrationStateType => ({
  ...state,
  locationIntegrationsLoading: false,
  locationIntegrationsError: action.locationIntegrationsError,
});

const toggleIntegrationStart = (
  state: LocationIntegrationStateType,
): LocationIntegrationStateType => ({
  ...state,
  locationIntegrationToggleLoading: true,
});

const toggleIntegrationSuccess = (
  state: LocationIntegrationStateType,
): LocationIntegrationStateType => ({
  ...state,
  locationIntegrationToggleLoading: false,
  locationIntegrationToggleSuccess: true,
  locationIntegrationsListUpdateNeeded: true,
});

const toggleIntegrationFail = (
  state: LocationIntegrationStateType,
  action: LocationIntegrationActionType,
): LocationIntegrationStateType => ({
  ...state,
  locationIntegrationToggleLoading: false,
  locationIntegrationToggleError: action.locationIntegrationToggleError,
});

const resetIntegrationStore = (): LocationIntegrationStateType => ({
  ...initialState,
});

const completelyResetObjectTypeStore = (): LocationIntegrationStateType => ({
  ...initialState,
});

const reducer = (
  state = initialState,
  action: LocationIntegrationActionType,
) => {
  switch (action.type) {
    case actionTypes.FETCH_LOCATION_INTEGRATION_LIST_START:
      return fetchIntegrationsListStart(state);
    case actionTypes.FETCH_LOCATION_INTEGRATION_LIST_SUCCESS:
      return fetchIntegrationsListSuccess(state, action);
    case actionTypes.FETCH_LOCATION_INTEGRATION_LIST_FAIL:
      return fetchIntegrationsListFail(state, action);
    case actionTypes.FETCH_LOCATION_INTEGRATIONS_START:
      return fetchIntegrationsStart(state);
    case actionTypes.FETCH_LOCATION_INTEGRATIONS_SUCCESS:
      return fetchIntegrationsSuccess(state, action);
    case actionTypes.FETCH_LOCATION_INTEGRATIONS_FAIL:
      return fetchIntegrationsFail(state, action);
    case actionTypes.TOGGLE_LOCATION_INTEGRATION_START:
      return toggleIntegrationStart(state);
    case actionTypes.TOGGLE_LOCATION_INTEGRATION_SUCCESS:
      return toggleIntegrationSuccess(state);
    case actionTypes.TOGGLE_LOCATION_INTEGRATION_FAIL:
      return toggleIntegrationFail(state, action);
    case actionTypes.RESET_LOCATION_INTEGRATION_STORE:
      return resetIntegrationStore();
    case actionTypes.LOGOUT:
      return completelyResetObjectTypeStore();
    default:
      return state;
  }
};

export default reducer;
