import axios from '../../config/Axios/axios-instance';
import { Dispatch } from 'redux';
import { ListParams } from '../../hooks/useList/useList';
import {
  fetchLocationsListStart,
  fetchLocationsListSuccess,
  fetchLocationsListFail,
  fetchLocationStart,
  fetchLocationSuccess,
  updateLocationStart,
  fetchLocationFail,
  updateLocationSuccess,
  updateLocationFail,
  deleteLocationStart,
  deleteLocationSuccess,
  deleteLocationFail,
  createLocationStart,
  createLocationSuccess,
  createLocationFail,
  updateLocationImagesStart,
  updateLocationImagesSuccess,
  updateLocationImagesFail,
  locationPublishStart,
  locationPublishSuccess,
  locationPublishFail,
  unpublishLocationStart,
  unpublishLocationSuccess,
  unpublishLocationFail,
  fetchAllLocationsStart,
  fetchAllLocationsFail,
  fetchAllLocationsSuccess,
  fetchPublicNewLocationsFail,
  fetchPublicNewLocationsStart,
  fetchPublicNewLocationsSuccess,
  fetchPublicPromotedLocationsFail,
  fetchPublicPromotedLocationsStart,
  fetchPublicPromotedLocationsSuccess,
  fetchPublicLocationsStart,
  fetchPublicLocationsSuccess,
  fetchPublicLocationsFail,
  fetchPublicFeaturedLocationsStart,
  fetchPublicFeaturedLocationsSuccess,
  fetchPublicFeaturedLocationsFail,
  fetchPublicSimilarLocationsStart,
  fetchPublicSimilarLocationsSuccess,
  fetchPublicSimilarLocationsFail,
  fetchPublicLocationStart,
  fetchPublicLocationSuccess,
  fetchPublicLocationFail,
  fetchSimilarLocationsStart,
  fetchSimilarLocationsSuccess,
  fetchSimilarLocationsFail,
  updateLocationBuildingPlansImagesStart,
  updateLocationBuildingPlansImagesSuccess,
  updateLocationBuildingPlansImagesFail,
  updateLocationGalleryLinksSuccess,
  updateLocationGalleryLinksStart,
  updateLocationGalleryLinksFail,
  viewRecommendedLocationsStart,
  viewRecommendedLocationsSuccess,
  viewRecommendedLocationsFail,
  calculatePercentagesStart,
  calculatePercentagesSuccess,
  calculatePercentagesFail,
  fetchPublishedLocationsIdsStart,
  fetchPublishedLocationsIdsSuccess,
  fetchPublishedLocationsIdsFail,
  bulkLocationsPublishFail,
  bulkLocationsPublishStart,
  bulkLocationsPublishSuccess,
} from './actions';
import { showToast } from '../../utility/toast/toast';
import { IntlShape } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import { getFormData } from '../../utility/form/formData';
import { EntityTranslation } from '../../domain/EntityTranslation';
import {
  LocationPropertySaleTypes,
  LocationPriceRangeTypes,
  LocationPropertyBathroomTypes,
  LocationPropertyBedroomTypes,
  LocationPropertySizeTypes,
  EnergyClass,
} from '../../domain/Location';
import { TablePriorityUpdate } from 'src/common/Table/Table';

const API_URL = '/locations';

export type PublicLocationsListParams = ListParams & {
  priceRange: LocationPriceRangeTypes | null;
  size: LocationPropertySizeTypes | null;
  types: string[];
  bedrooms: LocationPropertyBedroomTypes[];
  bathrooms: LocationPropertyBathroomTypes[];
  features: string[];
  cityId: number | null;
  saleType: LocationPropertySaleTypes | null;
  townAreaIds: string[];
  search: string;
};

export enum GetLocationType {
  // eslint-disable-next-line no-unused-vars
  GENERAL = 'GENERAL',
  // eslint-disable-next-line no-unused-vars
  PRICES = 'PRICES',
  // eslint-disable-next-line no-unused-vars
  FEATURES = 'FEATURES',
  // eslint-disable-next-line no-unused-vars
  GALLERY = 'GALLERY',
  // eslint-disable-next-line no-unused-vars
  INTEGRATIONS = 'INTEGRATIONS',
}

export type GetLocationDto = {
  type: GetLocationType;
};

export type LocationCreateRequest = {
  name: string;
  description: string;
  address: string;
  price: number;
  cityId: number;
  latitude: number;
  longitude: number;
  postCode: string;
  employeeIds: number[];
  amenitiesIds?: number[];
  ibi?: number;
  communityFees?: number;
  isPromoted: boolean;
  isExclusive: boolean;
  isNewDevelopment: boolean;
  seo: {
    slug: string;
    title: string;
    description: string;
    translations: EntityTranslation[];
  };
  translations: EntityTranslation[];
  energyRating: EnergyClass;
  co2EmissionRating: EnergyClass;
  energyConsumption: number;
  co2Emission: number;
  townAreaId: number;
};

export type LocationUpdateRequest = {
  id: number;
  name: string;
  description: string;
  address: string;
  price: number;
  cityId: number;
  latitude: number;
  longitude: number;
  postCode: string;
  employeeIds: number[];
  amenitiesIds?: number[];
  ibi?: number;
  communityFees?: number;
  isPromoted: boolean;
  isExclusive: boolean;
  isNewDevelopment: boolean;
  referenceNumber: string;
  seo: {
    slug: string;
    title: string;
    description: string;
    translations: EntityTranslation[];
  };
  translations: EntityTranslation[];
  energyRating: EnergyClass;
  co2EmissionRating: EnergyClass;
  energyConsumption: number;
  co2Emission: number;
  townAreaId: number;
};

export type LocationImagesUpdateRequest = {
  images: File[];
  imageFiles: number[];
  priorities: TablePriorityUpdate[];
};

export type LocationBuildingPlansImagesUpdateRequest = {
  images: File[];
  buildingPlansImageFiles: number[];
  priorities: TablePriorityUpdate[];
};

export type LocationUpdateGalleryLinksRequest = {
  videoUrl: string;
  view3dUrl: string;
};

export type PublishBulkLocationsRequest = {
  locationIds: number[];
  integrationIds: number[];
};

export const fetchLocationsList =
  (params: ListParams, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(fetchLocationsListStart());
    return axios
      .get(API_URL, { params })
      .then((response) => {
        dispatch(fetchLocationsListSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchLocationsListFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const fetchLocation =
  (id: number, params: GetLocationDto, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(fetchLocationStart());
    return axios
      .get(`${API_URL}/${id}`, { params })
      .then((response) => {
        dispatch(fetchLocationSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchLocationFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const createLocation =
  (inputs: LocationCreateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(createLocationStart());
    return axios
      .post(API_URL, {
        ...inputs,
      })
      .then((response) => {
        dispatch(createLocationSuccess(response.data));
        showToast(translate(intl, 'SUCCESS.LOCATION_CREATE'), 'success');
      })
      .catch((err) => {
        dispatch(createLocationFail(err?.response?.data?.message));
      });
  };

export const updateLocation =
  (inputs: LocationUpdateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(updateLocationStart());
    return axios
      .patch(`${API_URL}/${inputs.id}`, {
        ...inputs,
      })
      .then(() => {
        dispatch(updateLocationSuccess());
        showToast(translate(intl, 'SUCCESS.LOCATION_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updateLocationFail(err?.response?.data?.message));
      });
  };

export const deleteLocation =
  (id: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(deleteLocationStart());
    return axios
      .delete(`${API_URL}/${id}`)
      .then(() => {
        dispatch(deleteLocationSuccess());
        showToast(translate(intl, 'SUCCESS.LOCATION_DELETE'), 'success');
      })
      .catch((err) => {
        dispatch(deleteLocationFail(err?.response?.data?.message));
      });
  };

export const updateLocationImages =
  (id: number, inputs: LocationImagesUpdateRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(updateLocationImagesStart());
    return axios
      .patch(`${API_URL}/images/${id}`, getFormData(inputs))
      .then((response) => {
        dispatch(updateLocationImagesSuccess(response.data));
        showToast(translate(intl, 'SUCCESS.LOCATION_IMAGES_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updateLocationImagesFail(err?.response?.data?.message));
      });
  };

export const updateLocationBuildingPlansImages =
  (
    id: number,
    inputs: LocationBuildingPlansImagesUpdateRequest,
    intl: IntlShape,
  ) =>
  (dispatch: Dispatch) => {
    dispatch(updateLocationBuildingPlansImagesStart());
    return axios
      .patch(`${API_URL}/building-plans-images/${id}`, getFormData(inputs))
      .then((response) => {
        dispatch(updateLocationBuildingPlansImagesSuccess(response.data));
        showToast(
          translate(intl, 'SUCCESS.LOCATION_BUILDING_PLANS_IMAGES_UPDATE'),
          'success',
        );
      })
      .catch((err) => {
        dispatch(
          updateLocationBuildingPlansImagesFail(err?.response?.data?.message),
        );
      });
  };

export const publishLocation =
  (id: number, intl: IntlShape, isPublished: boolean) =>
  (dispatch: Dispatch) => {
    dispatch(locationPublishStart());
    return axios
      .patch(`${API_URL}/${id}/publish`)
      .then((response) => {
        dispatch(locationPublishSuccess());
        showToast(
          translate(
            intl,
            isPublished
              ? 'SUCCESS.REPUBLISH_LOCATION_SUCCESS'
              : 'SUCCESS.PUBLISH_LOCATION_SUCCESS',
          ),
          'success',
        );
      })
      .catch((err) => {
        dispatch(locationPublishFail(err?.response?.data?.message));
      });
  };

export const publishBulkLocation =
  (inputs: PublishBulkLocationsRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(bulkLocationsPublishStart());
    return axios
      .patch(`${API_URL}/bulk-publish`, { ...inputs })
      .then((response) => {
        dispatch(bulkLocationsPublishSuccess());
        showToast(
          translate(intl, 'SUCCESS.PUBLISH_BULK_LOCATIONS_SUCCESS'),
          'success',
        );
      })
      .catch((err) => {
        dispatch(bulkLocationsPublishFail(err?.response?.data?.message));
      });
  };

export const unpublishLocation =
  (id: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(unpublishLocationStart());
    return axios
      .patch(`${API_URL}/${id}/unpublish`)
      .then((response) => {
        dispatch(unpublishLocationSuccess());
        showToast(
          translate(intl, 'SUCCESS.UNPUBLISH_LOCATION_SUCCESS'),
          'success',
        );
      })
      .catch((err) => {
        dispatch(unpublishLocationFail(err?.response?.data?.message));
      });
  };

export const calculatePercentages =
  (id: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(calculatePercentagesStart());
    return axios
      .patch(`${API_URL}/${id}/calculate-percentages`)
      .then((response) => {
        dispatch(calculatePercentagesSuccess());
        showToast(
          translate(intl, 'SUCCESS.CALCULATE_PERCENTAGES_SUCCESS'),
          'success',
        );
      })
      .catch((err) => {
        dispatch(calculatePercentagesFail());
      });
  };

export const fetchAllLocations = (intl: IntlShape) => (dispatch: Dispatch) => {
  dispatch(fetchAllLocationsStart());
  return axios
    .get(`${API_URL}/all`)
    .then((response) => {
      dispatch(fetchAllLocationsSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchAllLocationsFail(err?.response?.data?.message));
      showToast(translate(intl, err?.response?.data?.message), 'error');
    });
};

export const fetchPublicNewLocations = () => (dispatch: Dispatch) => {
  dispatch(fetchPublicNewLocationsStart());
  return axios
    .get(`${API_URL}/public-new-locations`)
    .then((response) => {
      dispatch(fetchPublicNewLocationsSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchPublicNewLocationsFail(err?.response?.data?.message));
    });
};

export const fetchPublicPromotedLocations = () => (dispatch: Dispatch) => {
  dispatch(fetchPublicPromotedLocationsStart());
  return axios
    .get(`${API_URL}/public-promoted-locations`)
    .then((response) => {
      dispatch(fetchPublicPromotedLocationsSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchPublicPromotedLocationsFail(err?.response?.data?.message));
    });
};

export const fetchPublicFeaturedLocations = () => (dispatch: Dispatch) => {
  dispatch(fetchPublicFeaturedLocationsStart());
  return axios
    .get(`${API_URL}/public-featured-locations`)
    .then((response) => {
      dispatch(fetchPublicFeaturedLocationsSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchPublicFeaturedLocationsFail(err?.response?.data?.message));
    });
};

export const fetchPublicLocationsList =
  (params: PublicLocationsListParams, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(fetchPublicLocationsStart());
    return axios
      .get(`${API_URL}/public`, { params })
      .then((response) => {
        dispatch(fetchPublicLocationsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchPublicLocationsFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const fetchPublicSimilarLocations = () => (dispatch: Dispatch) => {
  dispatch(fetchPublicSimilarLocationsStart());
  return axios
    .get(`${API_URL}/public-similar-locations`)
    .then((response) => {
      dispatch(fetchPublicSimilarLocationsSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchPublicSimilarLocationsFail(err?.response?.data?.message));
    });
};

export const fetchPublishedLocationsIds = () => (dispatch: Dispatch) => {
  dispatch(fetchPublishedLocationsIdsStart());
  return axios
    .get(`${API_URL}/published-locations-ids`)
    .then((response) => {
      dispatch(fetchPublishedLocationsIdsSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchPublishedLocationsIdsFail(err?.response?.data?.message));
    });
};

export const fetchPublicLocation =
  (slug: string, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(fetchPublicLocationStart());
    return axios
      .get(`${API_URL}/${slug}/public`)
      .then((response) => {
        dispatch(fetchPublicLocationSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchPublicLocationFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const fetchSimilarLocations = (id: number) => (dispatch: Dispatch) => {
  dispatch(fetchSimilarLocationsStart());
  return axios
    .get(`${API_URL}/public/similar/${id}`)
    .then((response) => {
      dispatch(fetchSimilarLocationsSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchSimilarLocationsFail(err?.response?.data?.message));
    });
};

export const updateLocationGalleryLinks =
  (id: number, inputs: LocationUpdateGalleryLinksRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(updateLocationGalleryLinksStart());
    return axios
      .patch(`${API_URL}/gallery-links/${id}`, { ...inputs })
      .then(() => {
        dispatch(updateLocationGalleryLinksSuccess());
        showToast(
          translate(intl, 'SUCCESS.LOCATION_GALLERY_LINKS_UPDATE'),
          'success',
        );
      })
      .catch((err) => {
        dispatch(updateLocationGalleryLinksFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const viewRecommendedLocations =
  (id: number) => (dispatch: Dispatch) => {
    dispatch(viewRecommendedLocationsStart());
    return axios
      .patch(`${API_URL}/${id}/recommendation-status`)
      .then(() => {
        dispatch(viewRecommendedLocationsSuccess());
      })
      .catch((err) => {
        dispatch(viewRecommendedLocationsFail(err?.response?.data?.message));
      });
  };
