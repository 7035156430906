export const FETCH_LOCATIONS_LIST_START = 'FETCH_LOCATIONS_LIST_START';
export const FETCH_LOCATIONS_LIST_SUCCESS = 'FETCH_LOCATIONS_LIST_SUCCESS';
export const FETCH_LOCATIONS_LIST_FAIL = 'FETCH_LOCATIONS_LIST_FAIL';

export const FETCH_LOCATION_START = 'FETCH_LOCATION_START';
export const FETCH_LOCATION_SUCCESS = 'FETCH_LOCATION_SUCCESS';
export const FETCH_LOCATION_FAIL = 'FETCH_LOCATION_FAIL';

export const CALCULATE_PERCENTAGES_START = 'CALCULATE_PERCENTAGES_START';
export const CALCULATE_PERCENTAGES_SUCCESS = 'CALCULATE_PERCENTAGES_SUCCESS';
export const CALCULATE_PERCENTAGES_FAIL = 'CALCULATE_PERCENTAGES_FAIL';

export const CREATE_LOCATION_START = 'CREATE_LOCATION_START';
export const CREATE_LOCATION_SUCCESS = 'CREATE_LOCATION_SUCCESS';
export const CREATE_LOCATION_FAIL = 'CREATE_LOCATION_FAIL';

export const UPDATE_LOCATION_START = 'UPDATE_LOCATION_START';
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS';
export const UPDATE_LOCATION_FAIL = 'UPDATE_LOCATION_FAIL';

export const UPDATE_LOCATION_IMAGES_START = 'UPDATE_LOCATION_IMAGES_START';
export const UPDATE_LOCATION_IMAGES_SUCCESS = 'UPDATE_LOCATION_IMAGES_SUCCESS';
export const UPDATE_LOCATION_IMAGES_FAIL = 'UPDATE_LOCATION_IMAGES_FAIL';

export const UPDATE_LOCATION_BUILDING_PLANS_IMAGES_START =
  'UPDATE_LOCATION_BUILDING_PLANS_IMAGES_START';
export const UPDATE_LOCATION_BUILDING_PLANS_IMAGES_SUCCESS =
  'UPDATE_LOCATION_BUILDING_PLANS_IMAGES_SUCCESS';
export const UPDATE_LOCATION_BUILDING_PLANS_IMAGES_FAIL =
  'UPDATE_LOCATION_BUILDING_PLANS_IMAGES_FAIL';

export const DELETE_LOCATION_START = 'DELETE_LOCATION_START';
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS';
export const DELETE_LOCATION_FAIL = 'DELETE_LOCATION_FAIL';

export const PUBLISH_LOCATION_START = 'PUBLISH_LOCATION_START';
export const PUBLISH_LOCATION_SUCCESS = 'PUBLISH_LOCATION_SUCCESS';
export const PUBLISH_LOCATION_FAIL = 'PUBLISH_LOCATION_FAIL';

export const PUBLISH_BULK_LOCATION_START = 'PUBLISH_BULK_LOCATION_START';
export const PUBLISH_BULK_LOCATION_SUCCESS = 'PUBLISH_BULK_LOCATION_SUCCESS';
export const PUBLISH_BULK_LOCATION_FAIL = 'PUBLISH_BULK_LOCATION_FAIL';

export const UNPUBLISH_LOCATION_START = 'UNPUBLISH_LOCATION_START';
export const UNPUBLISH_LOCATION_SUCCESS = 'UNPUBLISH_LOCATION_SUCCESS';
export const UNPUBLISH_LOCATION_FAIL = 'UNPUBLISH_LOCATION_FAIL';

export const FETCH_ALL_LOCATIONS_START = 'FETCH_ALL_LOCATIONS_START';
export const FETCH_ALL_LOCATIONS_SUCCESS = 'FETCH_ALL_LOCATIONS_SUCCESS';
export const FETCH_ALL_LOCATIONS_FAIL = 'FETCH_ALL_LOCATIONS_FAIL';

export const FETCH_PUBLIC_NEW_LOCATIONS_START =
  'FETCH_PUBLIC_NEW_LOCATIONS_START';
export const FETCH_PUBLIC_NEW_LOCATIONS_SUCCESS =
  'FETCH_PUBLIC_NEW_LOCATIONS_SUCCESS';
export const FETCH_PUBLIC_NEW_LOCATIONS_FAIL =
  'FETCH_PUBLIC_NEW_LOCATIONS_FAIL';

export const FETCH_PUBLIC_PROMOTED_LOCATIONS_START =
  'FETCH_PUBLIC_PROMOTED_LOCATIONS_START';
export const FETCH_PUBLIC_PROMOTED_LOCATIONS_SUCCESS =
  'FETCH_PUBLIC_PROMOTED_LOCATIONS_SUCCESS';
export const FETCH_PUBLIC_PROMOTED_LOCATIONS_FAIL =
  'FETCH_PUBLIC_PROMOTED_LOCATIONS_FAIL';

export const FETCH_PUBLIC_FEATURED_LOCATIONS_START =
  'FETCH_PUBLIC_FEATURED_LOCATIONS_START';
export const FETCH_PUBLIC_FEATURED_LOCATIONS_SUCCESS =
  'FETCH_PUBLIC_FEATURED_LOCATIONS_SUCCESS';
export const FETCH_PUBLIC_FEATURED_LOCATIONS_FAIL =
  'FETCH_PUBLIC_FEATURED_LOCATIONS_FAIL';

export const FETCH_PUBLIC_LOCATIONS_START = 'FETCH_PUBLIC_LOCATIONS_START';
export const FETCH_PUBLIC_LOCATIONS_SUCCESS = 'FETCH_PUBLIC_LOCATIONS_SUCCESS';
export const FETCH_PUBLIC_LOCATIONS_FAIL = 'FETCH_PUBLIC_LOCATIONS_FAIL';

export const FETCH_PUBLIC_SIMILAR_LOCATIONS_START =
  'FETCH_PUBLIC_SIMILAR_LOCATIONS_START';
export const FETCH_PUBLIC_SIMILAR_LOCATIONS_SUCCESS =
  'FETCH_PUBLIC_SIMILAR_LOCATIONS_SUCCESS';
export const FETCH_PUBLIC_SIMILAR_LOCATIONS_FAIL =
  'FETCH_PUBLIC_SIMILAR_LOCATIONS_FAIL';

export const FETCH_PUBLIC_LOCATION_START = 'FETCH_PUBLIC_LOCATION_START';
export const FETCH_PUBLIC_LOCATION_SUCCESS = 'FETCH_PUBLIC_LOCATION_SUCCESS';
export const FETCH_PUBLIC_LOCATION_FAIL = 'FETCH_PUBLIC_LOCATION_FAIL';

export const FETCH_SIMILAR_LOCATIONS_START = 'FETCH_SIMILAR_LOCATIONS_START';
export const FETCH_SIMILAR_LOCATIONS_SUCCESS =
  'FETCH_SIMILAR_LOCATIONS_SUCCESS';
export const FETCH_SIMILAR_LOCATIONS_FAIL = 'FETCH_SIMILAR_LOCATIONS_FAIL';

export const FETCH_PUBLISHED_LOCATIONS_IDS_START =
  'FETCH_PUBLISHED_LOCATIONS_IDS_START';
export const FETCH_PUBLISHED_LOCATIONS_IDS_SUCCESS =
  'FETCH_PUBLISHED_LOCATIONS_IDS_SUCCESS';
export const FETCH_PUBLISHED_LOCATIONS_IDS_FAIL =
  'FETCH_PUBLISHED_LOCATIONS_IDS_FAIL';

export const UPDATE_LOCATION_GALLERY_LINKS_START =
  'UPDATE_LOCATION_GALLERY_LINKS_START';
export const UPDATE_LOCATION_GALLERY_LINKS_SUCCESS =
  'UPDATE_LOCATION_GALLERY_LINKS_SUCCESS';
export const UPDATE_LOCATION_GALLERY_LINKS_FAIL =
  'UPDATE_LOCATION_GALLERY_LINKS_FAIL';

export const VIEW_RECOMMENDED_LOCATIONS_START =
  'VIEW_RECOMMENDED_LOCATIONS_START';
export const VIEW_RECOMMENDED_LOCATIONS_SUCCESS =
  'VIEW_RECOMMENDED_LOCATIONS_SUCCESS';
export const VIEW_RECOMMENDED_LOCATIONS_FAIL =
  'VIEW_RECOMMENDED_LOCATIONS_FAIL';

export const UPDATE_SAVED_PUBLIC_LOCATIONS = 'UPDATE_SAVED_PUBLIC_LOCATIONS';

export const SET_PUBLIC_SCROLL_TO_ID = 'SET_PUBLIC_SCROLL_TO_ID';

export const RESET_LOCATION_GALLERY_LINKS_UPDATE_STORE =
  'RESET_LOCATION_GALLERY_LINKS_UPDATE_STORE';
export const RESET_UPLOAD_LOCATION_IMAGE = 'RESET_UPLOAD_LOCATION_IMAGE';
export const RESET_UPDATE_LOCATION_BUILDING_PLANS_IMAGES =
  'RESET_UPDATE_LOCATION_BUILDING_PLANS_IMAGES';
export const RESET_CREATED_LOCATION_STORE = 'RESET_CREATED_LOCATION_STORE';
export const RESET_LOCATION_STORE = 'RESET_LOCATION_STORE';
export const RESET_LOCATION_ERROR = 'RESET_LOCATION_ERROR';
export const RESET_UPDATED_LOCATION_STORE = 'RESET_UPDATED_LOCATION_STORE';
export const RESET_PUBLIC_SCROLL_TO_ID = 'RESET_PUBLIC_SCROLL_TO_ID';

export const LOGOUT = 'LOGOUT';

export type LocationActionTypes =
  | typeof FETCH_LOCATIONS_LIST_START
  | typeof FETCH_LOCATIONS_LIST_SUCCESS
  | typeof FETCH_LOCATIONS_LIST_FAIL
  | typeof FETCH_LOCATION_START
  | typeof FETCH_LOCATION_SUCCESS
  | typeof FETCH_LOCATION_FAIL
  | typeof CALCULATE_PERCENTAGES_START
  | typeof CALCULATE_PERCENTAGES_SUCCESS
  | typeof CALCULATE_PERCENTAGES_FAIL
  | typeof CREATE_LOCATION_START
  | typeof CREATE_LOCATION_SUCCESS
  | typeof CREATE_LOCATION_FAIL
  | typeof UPDATE_LOCATION_START
  | typeof UPDATE_LOCATION_SUCCESS
  | typeof UPDATE_LOCATION_FAIL
  | typeof DELETE_LOCATION_START
  | typeof DELETE_LOCATION_SUCCESS
  | typeof DELETE_LOCATION_FAIL
  | typeof UPDATE_LOCATION_IMAGES_START
  | typeof UPDATE_LOCATION_IMAGES_SUCCESS
  | typeof UPDATE_LOCATION_IMAGES_FAIL
  | typeof UPDATE_LOCATION_BUILDING_PLANS_IMAGES_START
  | typeof UPDATE_LOCATION_BUILDING_PLANS_IMAGES_SUCCESS
  | typeof UPDATE_LOCATION_BUILDING_PLANS_IMAGES_FAIL
  | typeof PUBLISH_LOCATION_START
  | typeof PUBLISH_LOCATION_SUCCESS
  | typeof PUBLISH_LOCATION_FAIL
  | typeof PUBLISH_BULK_LOCATION_START
  | typeof PUBLISH_BULK_LOCATION_SUCCESS
  | typeof PUBLISH_BULK_LOCATION_FAIL
  | typeof UNPUBLISH_LOCATION_START
  | typeof UNPUBLISH_LOCATION_SUCCESS
  | typeof UNPUBLISH_LOCATION_FAIL
  | typeof FETCH_ALL_LOCATIONS_START
  | typeof FETCH_ALL_LOCATIONS_SUCCESS
  | typeof FETCH_ALL_LOCATIONS_FAIL
  | typeof FETCH_PUBLIC_NEW_LOCATIONS_START
  | typeof FETCH_PUBLIC_NEW_LOCATIONS_SUCCESS
  | typeof FETCH_PUBLIC_NEW_LOCATIONS_FAIL
  | typeof FETCH_PUBLIC_PROMOTED_LOCATIONS_START
  | typeof FETCH_PUBLIC_PROMOTED_LOCATIONS_SUCCESS
  | typeof FETCH_PUBLIC_PROMOTED_LOCATIONS_FAIL
  | typeof FETCH_PUBLIC_FEATURED_LOCATIONS_START
  | typeof FETCH_PUBLIC_FEATURED_LOCATIONS_SUCCESS
  | typeof FETCH_PUBLIC_FEATURED_LOCATIONS_FAIL
  | typeof FETCH_PUBLIC_LOCATIONS_START
  | typeof FETCH_PUBLIC_LOCATIONS_SUCCESS
  | typeof FETCH_PUBLIC_LOCATIONS_FAIL
  | typeof FETCH_PUBLIC_SIMILAR_LOCATIONS_START
  | typeof FETCH_PUBLIC_SIMILAR_LOCATIONS_SUCCESS
  | typeof FETCH_PUBLIC_SIMILAR_LOCATIONS_FAIL
  | typeof FETCH_PUBLIC_LOCATION_START
  | typeof FETCH_PUBLIC_LOCATION_SUCCESS
  | typeof FETCH_PUBLIC_LOCATION_FAIL
  | typeof FETCH_SIMILAR_LOCATIONS_START
  | typeof FETCH_SIMILAR_LOCATIONS_SUCCESS
  | typeof FETCH_SIMILAR_LOCATIONS_FAIL
  | typeof FETCH_PUBLISHED_LOCATIONS_IDS_START
  | typeof FETCH_PUBLISHED_LOCATIONS_IDS_SUCCESS
  | typeof FETCH_PUBLISHED_LOCATIONS_IDS_FAIL
  | typeof UPDATE_LOCATION_GALLERY_LINKS_START
  | typeof UPDATE_LOCATION_GALLERY_LINKS_SUCCESS
  | typeof UPDATE_LOCATION_GALLERY_LINKS_FAIL
  | typeof UPDATE_SAVED_PUBLIC_LOCATIONS
  | typeof VIEW_RECOMMENDED_LOCATIONS_START
  | typeof VIEW_RECOMMENDED_LOCATIONS_SUCCESS
  | typeof VIEW_RECOMMENDED_LOCATIONS_FAIL
  | typeof RESET_LOCATION_GALLERY_LINKS_UPDATE_STORE
  | typeof RESET_UPLOAD_LOCATION_IMAGE
  | typeof RESET_UPDATE_LOCATION_BUILDING_PLANS_IMAGES
  | typeof RESET_LOCATION_STORE
  | typeof RESET_CREATED_LOCATION_STORE
  | typeof RESET_LOCATION_ERROR
  | typeof RESET_UPDATED_LOCATION_STORE
  | typeof RESET_PUBLIC_SCROLL_TO_ID
  | typeof SET_PUBLIC_SCROLL_TO_ID
  | typeof LOGOUT;
