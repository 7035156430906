import { Locale } from '../../domain/Translation';

export const getLocaleUrl = (route: string, locale: Locale) =>
  route.replace(':locale', locale);

export const getYoutubeVideoKeyFromUrl = (url: string) => {
  const regex =
    // eslint-disable-next-line max-len
    /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

  const match = url.match(regex);

  return match ? match[1] : null;
};

export const getListName = (route: String) => {
  if (!route.includes('admin')) {
    return '';
  }

  const parts = location.pathname.split('/');
  return parts[parts.findIndex((part) => part === 'admin') + 1];
};
