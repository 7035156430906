import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Location } from '../../domain/Location';
import { PublicLocation } from '../../domain/PublicLocation';
import { NavigationScrollId } from './reducer';

export const fetchLocationsListStart = () => ({
  type: actionTypes.FETCH_LOCATIONS_LIST_START,
});

export const fetchLocationsListSuccess = (
  locationsList: ListResults<Location>,
) => ({
  type: actionTypes.FETCH_LOCATIONS_LIST_SUCCESS,
  locationsList,
});

export const fetchLocationsListFail = (locationsListError: HttpError) => ({
  type: actionTypes.FETCH_LOCATIONS_LIST_FAIL,
  locationsListError,
});

export const fetchLocationStart = () => ({
  type: actionTypes.FETCH_LOCATION_START,
});

export const fetchLocationSuccess = (location: Location) => ({
  type: actionTypes.FETCH_LOCATION_SUCCESS,
  location,
});

export const fetchLocationFail = (locationError: HttpError) => ({
  type: actionTypes.FETCH_LOCATION_FAIL,
  locationError,
});

export const fetchPublishedLocationsIdsStart = () => ({
  type: actionTypes.FETCH_PUBLISHED_LOCATIONS_IDS_START,
});

export const fetchPublishedLocationsIdsSuccess = (
  publishedLocationsIds: number[],
) => ({
  type: actionTypes.FETCH_PUBLISHED_LOCATIONS_IDS_SUCCESS,
  publishedLocationsIds,
});

export const fetchPublishedLocationsIdsFail = (
  publishedLocationsIdsError: HttpError,
) => ({
  type: actionTypes.FETCH_PUBLISHED_LOCATIONS_IDS_FAIL,
  publishedLocationsIdsError,
});

export const calculatePercentagesStart = () => ({
  type: actionTypes.CALCULATE_PERCENTAGES_START,
});

export const calculatePercentagesSuccess = () => ({
  type: actionTypes.CALCULATE_PERCENTAGES_SUCCESS,
});

export const calculatePercentagesFail = () => ({
  type: actionTypes.CALCULATE_PERCENTAGES_FAIL,
});

export const createLocationStart = () => ({
  type: actionTypes.CREATE_LOCATION_START,
});

export const createLocationSuccess = (createdLocation: Location) => ({
  type: actionTypes.CREATE_LOCATION_SUCCESS,
  createdLocation,
});

export const createLocationFail = (locationCreateError: HttpError) => ({
  type: actionTypes.CREATE_LOCATION_FAIL,
  locationCreateError,
});

export const updateLocationStart = () => ({
  type: actionTypes.UPDATE_LOCATION_START,
});

export const updateLocationSuccess = () => ({
  type: actionTypes.UPDATE_LOCATION_SUCCESS,
});

export const updateLocationFail = (locationUpdateError: HttpError) => ({
  type: actionTypes.UPDATE_LOCATION_FAIL,
  locationUpdateError,
});

export const deleteLocationStart = () => ({
  type: actionTypes.DELETE_LOCATION_START,
});

export const deleteLocationSuccess = () => ({
  type: actionTypes.DELETE_LOCATION_SUCCESS,
});

export const deleteLocationFail = (locationDeleteError: HttpError) => ({
  type: actionTypes.DELETE_LOCATION_FAIL,
  locationDeleteError,
});

export const updateLocationImagesStart = () => ({
  type: actionTypes.UPDATE_LOCATION_IMAGES_START,
});

export const updateLocationImagesSuccess = (location: Location) => ({
  type: actionTypes.UPDATE_LOCATION_IMAGES_SUCCESS,
  location,
});

export const updateLocationImagesFail = (
  locationImagesUpdateError: HttpError,
) => ({
  type: actionTypes.UPDATE_LOCATION_IMAGES_FAIL,
  locationImagesUpdateError,
});

export const updateLocationBuildingPlansImagesStart = () => ({
  type: actionTypes.UPDATE_LOCATION_BUILDING_PLANS_IMAGES_START,
});

export const updateLocationBuildingPlansImagesSuccess = (
  location: Location,
) => ({
  type: actionTypes.UPDATE_LOCATION_BUILDING_PLANS_IMAGES_SUCCESS,
  location,
});

export const updateLocationBuildingPlansImagesFail = (
  locationBuildingPlansImagesUpdateError: HttpError,
) => ({
  type: actionTypes.UPDATE_LOCATION_BUILDING_PLANS_IMAGES_FAIL,
  locationBuildingPlansImagesUpdateError,
});

export const resetUpdateLocationBuildingPlansImages = () => ({
  type: actionTypes.RESET_UPDATE_LOCATION_BUILDING_PLANS_IMAGES,
});

export const locationPublishStart = () => ({
  type: actionTypes.PUBLISH_LOCATION_START,
});

export const locationPublishSuccess = () => ({
  type: actionTypes.PUBLISH_LOCATION_SUCCESS,
});

export const locationPublishFail = (locationPublishError: HttpError) => ({
  type: actionTypes.PUBLISH_LOCATION_FAIL,
  locationPublishError,
});

export const bulkLocationsPublishStart = () => ({
  type: actionTypes.PUBLISH_BULK_LOCATION_START,
});

export const bulkLocationsPublishSuccess = () => ({
  type: actionTypes.PUBLISH_BULK_LOCATION_SUCCESS,
});

export const bulkLocationsPublishFail = (
  bulkLocationPublishError: HttpError,
) => ({
  type: actionTypes.PUBLISH_BULK_LOCATION_FAIL,
  bulkLocationPublishError,
});

export const unpublishLocationStart = () => ({
  type: actionTypes.UNPUBLISH_LOCATION_START,
});

export const unpublishLocationSuccess = () => ({
  type: actionTypes.UNPUBLISH_LOCATION_SUCCESS,
});

export const unpublishLocationFail = (unpublishLocationError: HttpError) => ({
  type: actionTypes.UNPUBLISH_LOCATION_START,
  unpublishLocationError,
});

export const resetCreatedLocationStore = () => ({
  type: actionTypes.RESET_CREATED_LOCATION_STORE,
});

export const resetUploadLocationImage = () => ({
  type: actionTypes.RESET_UPLOAD_LOCATION_IMAGE,
});

export const resetLocationStore = () => ({
  type: actionTypes.RESET_LOCATION_STORE,
});

export const completelyResetLocationStore = () => ({
  type: actionTypes.LOGOUT,
});

export const fetchAllLocationsStart = () => ({
  type: actionTypes.FETCH_ALL_LOCATIONS_START,
});

export const fetchAllLocationsSuccess = (allLocations: Location[]) => ({
  type: actionTypes.FETCH_ALL_LOCATIONS_SUCCESS,
  allLocations,
});

export const fetchAllLocationsFail = (allLocationsError: HttpError) => ({
  type: actionTypes.FETCH_ALL_LOCATIONS_FAIL,
  allLocationsError,
});

export const fetchPublicNewLocationsStart = () => ({
  type: actionTypes.FETCH_PUBLIC_NEW_LOCATIONS_START,
});

export const fetchPublicNewLocationsSuccess = (
  publicNewLocations: PublicLocation[],
) => ({
  type: actionTypes.FETCH_PUBLIC_NEW_LOCATIONS_SUCCESS,
  publicNewLocations,
});

export const fetchPublicNewLocationsFail = (
  publicNewLocationsError: HttpError,
) => ({
  type: actionTypes.FETCH_PUBLIC_NEW_LOCATIONS_FAIL,
  publicNewLocationsError,
});

export const fetchPublicPromotedLocationsStart = () => ({
  type: actionTypes.FETCH_PUBLIC_PROMOTED_LOCATIONS_START,
});

export const fetchPublicPromotedLocationsSuccess = (
  publicPromotedLocations: PublicLocation[],
) => ({
  type: actionTypes.FETCH_PUBLIC_PROMOTED_LOCATIONS_SUCCESS,
  publicPromotedLocations,
});

export const fetchPublicPromotedLocationsFail = (
  publicPromotedLocationsError: HttpError,
) => ({
  type: actionTypes.FETCH_PUBLIC_PROMOTED_LOCATIONS_FAIL,
  publicPromotedLocationsError,
});

export const fetchPublicFeaturedLocationsStart = () => ({
  type: actionTypes.FETCH_PUBLIC_FEATURED_LOCATIONS_START,
});

export const fetchPublicFeaturedLocationsSuccess = (
  publicFeaturedLocations: PublicLocation[],
) => ({
  type: actionTypes.FETCH_PUBLIC_FEATURED_LOCATIONS_SUCCESS,
  publicFeaturedLocations,
});

export const fetchPublicFeaturedLocationsFail = (
  publicFeaturedLocationsError: HttpError,
) => ({
  type: actionTypes.FETCH_PUBLIC_FEATURED_LOCATIONS_FAIL,
  publicFeaturedLocationsError,
});

export const fetchPublicLocationsStart = () => ({
  type: actionTypes.FETCH_PUBLIC_LOCATIONS_START,
});

export const fetchPublicLocationsSuccess = (
  publicLocations: ListResults<Location>,
) => ({
  type: actionTypes.FETCH_PUBLIC_LOCATIONS_SUCCESS,
  publicLocations,
});

export const fetchPublicLocationsFail = (publicLocationsError: HttpError) => ({
  type: actionTypes.FETCH_PUBLIC_LOCATIONS_FAIL,
  publicLocationsError,
});

export const fetchSimilarLocationsStart = () => ({
  type: actionTypes.FETCH_SIMILAR_LOCATIONS_START,
});

export const fetchSimilarLocationsSuccess = (similarLocations: Location[]) => ({
  type: actionTypes.FETCH_SIMILAR_LOCATIONS_SUCCESS,
  similarLocations,
});

export const fetchSimilarLocationsFail = (
  similarLocationsError: HttpError,
) => ({
  type: actionTypes.FETCH_SIMILAR_LOCATIONS_FAIL,
  similarLocationsError,
});

export const resetLocationError = () => ({
  type: actionTypes.RESET_LOCATION_ERROR,
});

export const fetchPublicSimilarLocationsStart = () => ({
  type: actionTypes.FETCH_PUBLIC_SIMILAR_LOCATIONS_START,
});

export const fetchPublicSimilarLocationsSuccess = (
  publicSimilarLocations: ListResults<Location>,
) => ({
  type: actionTypes.FETCH_PUBLIC_SIMILAR_LOCATIONS_SUCCESS,
  publicSimilarLocations,
});

export const fetchPublicSimilarLocationsFail = (
  publicSimilarLocationsError: HttpError,
) => ({
  type: actionTypes.FETCH_PUBLIC_SIMILAR_LOCATIONS_FAIL,
  publicSimilarLocationsError,
});

export const fetchPublicLocationStart = () => ({
  type: actionTypes.FETCH_PUBLIC_LOCATION_START,
});

export const fetchPublicLocationSuccess = (publicLocation: Location) => ({
  type: actionTypes.FETCH_PUBLIC_LOCATION_SUCCESS,
  publicLocation,
});

export const fetchPublicLocationFail = (publicLocationError: HttpError) => ({
  type: actionTypes.FETCH_PUBLIC_LOCATION_FAIL,
  publicLocationError,
});

export const resetUpdatedLocationStore = () => ({
  type: actionTypes.RESET_UPDATED_LOCATION_STORE,
});

export const updateLocationGalleryLinksStart = () => ({
  type: actionTypes.UPDATE_LOCATION_GALLERY_LINKS_START,
});

export const updateLocationGalleryLinksSuccess = () => ({
  type: actionTypes.UPDATE_LOCATION_GALLERY_LINKS_SUCCESS,
});

export const updateLocationGalleryLinksFail = (
  locationUpdateGalleryLinksError: HttpError,
) => ({
  type: actionTypes.UPDATE_LOCATION_GALLERY_LINKS_FAIL,
  locationUpdateGalleryLinksError,
});

export const resetLocationGalleryLinksUpdateStore = () => ({
  type: actionTypes.RESET_LOCATION_GALLERY_LINKS_UPDATE_STORE,
});

export const updateSavedPublicLocations = (
  publicSavedLocation: PublicLocation | PublicLocation[],
) => ({
  type: actionTypes.UPDATE_SAVED_PUBLIC_LOCATIONS,
  publicSavedLocation,
});

export const viewRecommendedLocationsStart = () => ({
  type: actionTypes.VIEW_RECOMMENDED_LOCATIONS_START,
});

export const viewRecommendedLocationsSuccess = () => ({
  type: actionTypes.VIEW_RECOMMENDED_LOCATIONS_SUCCESS,
});

export const viewRecommendedLocationsFail = (
  viewRecommendedLocationsError: HttpError,
) => ({
  type: actionTypes.VIEW_RECOMMENDED_LOCATIONS_FAIL,
  viewRecommendedLocationsError,
});

export const setPublicScrollToId = (
  publicContactScrollToId: NavigationScrollId,
) => ({
  type: actionTypes.SET_PUBLIC_SCROLL_TO_ID,
  publicContactScrollToId,
});

export const resetPublicScrollToId = () => ({
  type: actionTypes.RESET_PUBLIC_SCROLL_TO_ID,
});
